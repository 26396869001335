<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/epocrates_plugin.png"
          alt="epocrates logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Epocrates
      </template>
      <template #subheader>
        Please configure the following in order to install the Epocrates plugin.
      </template>
    </PageHeader>

    <div class="section-header">
      Configuration
    </div>
    <div>
      Please map the following file headers to a custom field.
    </div>
    <v-simple-table class="field-table mt-3">
      <thead>
        <tr>
          <th>File Header</th>
          <th>Description</th>
          <th>Custom Field</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="mappedFileHeader in mappedFileHeaders"
          :key="mappedFileHeader.header"
        >
          <td>{{ mappedFileHeader.header }}</td>
          <td>{{ mappedFileHeader.description }}</td>
          <td>
            <CustomDropdown
              v-model="fieldMapping[mappedFileHeader.customField]"
              :items="customFieldOptions"
              autocomplete
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <hr class="mt-7">

    <validation-observer ref="autoImportConfigs">
      <AutomatedImportConfigurations
        :id="id"
        :plugin-data="pluginData"
        :import-configurations="importConfigurations"
        :import-type="importType"
        :file-extensions="fileExtensionOptions"
        @add-configuration="addImportConfiguration"
        @delete-configuration="deleteImportConfiguration"
        @update-configuration="updateImportValue"
      />
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin(false)"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="pluginData"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import importExportMixin from "@/utils/import-export-mixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import AutomatedImportConfigurations from "@/views/Plugins/components/AutomatedImportConfigurations";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginEpocrates",
  metaInfo: {
    title: 'Install Epocrates'
  },
  components: {
    AutomatedImportConfigurations,
    PageHeader,
    CustomDropdown,
    DeactivateConfirmDialog,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    fieldMapping: {},
    pluginData: null,
    mappedFileHeaders: [
      {
        header: 'User_Account_Key',
        customField: 'Epocrates ID',
        description: 'Epocrates unique user identifier'
      },
      {
        header: 'Occupation',
        customField: 'Occupation',
        description: 'User occupation provided to Epocrates by HCPs'
      },
      {
        header: 'ME_Number',
        customField: 'ME Number',
        description: '10-digit Medical Education Number, left padded with \'0\'s'
      },
    ],
    importType: 'epocrates_action',
    fileExtensionOptions: [
      { id: 'txt', text: 'Pipe-Delimited Text File (.txt)' },
    ],
  }),
  computed: {
    customFieldOptions() {
      const customFields = this.$store.getters['user/customFields']?.contact;

      if (!customFields) {
        return [];
      }

      return [
        {
          label: 'Automatically create a custom field',
          value: null,
        },
        ...customFields.map((field) => {
          return {
            label: field.name,
            value: field.fieldName,
          };
        })
      ];
    },
  },
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.pluginData = {...resp.data};

      this.fieldMapping = resp.data.pluginConfiguration?.fieldMapping ?? {};
      if (Array.isArray(this.fieldMapping)) {
        this.fieldMapping = {};
      }

      if (resp.data.pluginConfiguration?.importConfigurations.length) {
        const credentials = resp.data.pluginConfiguration?.credentials;

        this.setupImportConfigurations(resp.data.pluginConfiguration?.importConfigurations, credentials);
      }
    },
    async installPlugin(returnResponse) {
      const pluginConfiguration = this.getFormattedPluginConfiguration(this.importConfigurations.slice());
      pluginConfiguration.fieldMapping = this.fieldMapping;

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            // Reload the selected account in case custom fields got created
            const selectedAccount = this.$store.getters["user/account"];
            this.$store.dispatch(
              'user/selectAccount',
              selectedAccount.accountId,
              selectedAccount.logo
            );

            return resp;
          })
          .catch((error) => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (returnResponse) {
        return response;
      }
      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    },
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
.field-table {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
